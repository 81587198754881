@font-face {
    font-family: "Poppins Ligth";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Ligth"),
    url("https://bonnadona-public.s3.amazonaws.com/assets/fonts/Poppins-Light_ixvvwm.ttf")
    format("woff");
}
@font-face {
    font-family: "Poppins Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Regular"),
    url("https://bonnadona-public.s3.amazonaws.com/assets/fonts/Poppins-Regular_lzqdk3.ttf")
    format("woff");
}
@font-face {
    font-family: "Poppins Medium";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Medium"),
    url("https://bonnadona-public.s3.amazonaws.com/assets/fonts/Poppins-Medium_jtdrwz.ttf")
    format("woff");
}
@font-face {
    font-family: "Poppins ExtraLight";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins ExtraLight"),
    url("https://bonnadona-public.s3.amazonaws.com/assets/fonts/Poppins-ExtraLight_ytujuz.ttf")
    format("woff");
}
.container{
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.signature-container{
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    margin-top:1em;
}
.signature{
    width: 90%;
    height: 100%;
    border: solid 1px black;
}

.signature__buttons {
    display: flex;
    justify-content: center;
    margin: auto 0;
}

.signature__button{
    background: linear-gradient(
            270deg
            ,#FFEF6D 0%,#FFDD63 19%,#E0AE39 62%,#FCE068 100%) 0% 0% no-repeat padding-box;
    color: white;
    margin: 0 0.5vw;
    border-radius: 0.3vw !important;
    font-family: "Poppins Medium";
    font-size: 1em;
    cursor: pointer;
    min-width: 10vw;
    border: none;
    outline: none;
    padding: 0.5em 1em;
}