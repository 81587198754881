.error{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.error__image-container{
    width: 10em;
}
.error__image{
    width: 100%;
}
.error__title{
    margin: 1em 0;
    font-size: 1.5em;
    font-family: Poppins Medium;
}